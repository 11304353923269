import React from "react"
import Layout from "../layouts/index"
import SEO from "../layouts/seo"

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404 未找到页面" lang="zh" />
      <main className="col-md-10 col-xs-12 main-content">
        <header className="row normal-header notfound-header">
          <div className="bg-mask"></div>
          <section className="section 404-section col-sm-6 col-sm-offset-3 col-xs text-center">
            <h1>404 未找到页面</h1>
          </section>
        </header>
      </main>
    </Layout>
  )
}

export default NotFoundPage
